import React from 'react'
import PropTypes from 'prop-types'
import {
  VideoEmbedMain,
  VideoEmbedIframe,
  VideoEmbedOverlay,
} from './index.style'

const VideoEmbed = ({ type, id, autoplay, loop }) => {
  const isYouTube = type
  const isVimeo = !type
  let src

  if (isYouTube) {
    src = `https://www.youtube.com/embed/${id}?rel=0${
      autoplay ? '&autoplay=1&mute=1&controls=0&modestbranding=1' : ''
    }${loop ? `&loop=1&playlist=${id}` : ``}`
  }

  if (isVimeo) {
    src = `https://player.vimeo.com/video/${id}?byline=0&portrait=0${
      autoplay ? '&autoplay=1&muted=1' : ''
    }${loop ? `&loop=1` : ``}`
  }

  return (
    <VideoEmbedMain>
      <VideoEmbedIframe
        type="text/html"
        src={src}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />

      {autoplay && <VideoEmbedOverlay />}
    </VideoEmbedMain>
  )
}

VideoEmbed.propTypes = {
  type: PropTypes.bool,
  id: PropTypes.string,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
}

export default VideoEmbed
