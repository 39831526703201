import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'

export const IconButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  color: currentColor;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  transition: box-shadow 0.3s ease;

  .keyboard-in-use &:focus {
    outline: none;
    box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.25);
  }
`

export const IconButtonBorder = styled.div`
  position: absolute;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  border: 0.1rem solid ${colors.light};
  border-radius: 50%;
  will-change: transform;
  transition: transform 0.3s ease;

  ${IconButtonMain}:hover & {
    transform: scale(1.12);
  }
`
