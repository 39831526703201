import React from 'react'

const IconArrowheadLeft = ({
  width = 16,
  height = 22,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 22"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path stroke={fill} d="M11 1L1 11l10 10" />
    </svg>
  )
}

export default IconArrowheadLeft
