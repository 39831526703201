import React from 'react'
import { graphql } from 'gatsby'
import Work from '../components/Work'
import Seo from '../components/Seo'

const WorkTemplate = ({ data, pageContext }) => {
  const {
    seoDescription: { seoDescription },
    title,
    name,
    challenge,
    solution,
    videoPlayer,
    image,
    results,
  } = data.contentfulCaseStudy
  return (
    <>
      <Seo title={name} description={seoDescription} />

      <Work
        title={title}
        name={name}
        challenge={challenge}
        solution={solution}
        video={videoPlayer}
        image={image}
        results={results}
        next={pageContext.next}
      />
    </>
  )
}

export default WorkTemplate

export const pageQuery = graphql`
  query WorkBySlug($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      name
      challenge {
        raw
      }
      solution {
        raw
      }
      videoPlayer {
        type
        videoPlayerId
        autoplay
        loop
      }
      image {
        title
        description
        gatsbyImageData(width: 1200, placeholder: BLURRED, quality: 100)
      }
      results {
        resultText {
          resultText
        }
      }
      seoDescription {
        seoDescription
      }
      title
      featureImage {
        id
      }
    }
  }
`
