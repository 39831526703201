import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { TextUILabel } from '../../styles/vars/textStyles.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const WorkSection = styled.div`
  position: relative;

  ${TextUILabel} {
    padding-left: 2.4rem;

    ${mq.tabletL} {
      padding-left: 0;
    }
  }
`

export const WorkSectionHeader = styled.div``

export const WorkVideo = styled.div`
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`

export const WorkImage = styled.div`
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 1.5rem;
`

export const WorkResults = styled.div`
  overflow: hidden;
`

export const WorkResultsTrack = styled.ul.attrs(({ activeIndex }) => {
  return {
    style: {
      transform: `translateX(-${activeIndex * 100}%)`,
    },
  }
})`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
`

export const WorkResult = styled.li`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

export const WorkResultsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WorkResultsNav = styled.nav`
  display: flex;
  align-items: center;
`

export const WorkNext = styled.div`
  position: relative;

  ${PageTransitionLinkMain} {
    display: block;
  }
`

export const WorkNextHR = styled.div`
  position: relative;
  height: 0.1rem;
  background-color: ${transparentize(0.4, colors.grey)};

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: ${({ hovered }) => (hovered ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: ${({ hovered }) => (hovered ? '0% 50%' : '100% 50%')};
    display: block;
    background-color: ${colors.light};
    will-change: transform;
    transition: transform 0.6s ${easings.inOut.default};
  }
`
