import React from 'react'
import PropTypes from 'prop-types'
import { IconButtonBorder, IconButtonMain } from './index.style'

const IconButton = ({ icon, label, ...props }) => (
  <IconButtonMain aria-label={label} {...props}>
    <IconButtonBorder />
    {icon}
  </IconButtonMain>
)

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default IconButton
