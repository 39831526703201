import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import ScrollSection from '../ScrollSection'
import Spacer from '../Spacer'
import Footer from '../Footer'
import PageTransitionLink from '../PageTransitionLink'
import IconButton from '../IconButton'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import Tetris from '../Tetris'
import Flow from '../Flow'
import RichText from '../RichText'
import {
  Heading2,
  Heading3,
  TextBodyLarge,
  TextBodySmall,
  TextUILabel,
} from '../TextStyles'
import {
  WorkImage,
  WorkVideo,
  WorkNext,
  WorkNextHR,
  WorkResult,
  WorkResults,
  WorkResultsFooter,
  WorkResultsNav,
  WorkResultsTrack,
  WorkSection,
  WorkSectionHeader,
} from './index.style'
import { animation } from '../../styles/vars/animation.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { ContextDispatch } from '../../context/Context'
import VideoEmbed from '../VideoEmbed'
import { useEffect } from 'react'

const Work = ({
  title,
  name,
  challenge,
  video,
  image,
  solution,
  results,
  next,
}) => {
  const {
    site: {
      siteMetadata: { routes },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          routes {
            work
          }
        }
      }
    }
  `)
  const loadADelay = animation.siteLoadDelay + animation.pageEntryDuration
  const loadBDelay = loadADelay + 0.5
  const resultsLength = results.length
  const resultsLengthPad = results.length
  const [resultIndex, setResultIndex] = useState(0)
  const resultIndexPad = resultIndex + 1
  const [nextHovered, setNextHovered] = useState(false)
  const dispatch = useContext(ContextDispatch)

  const decrementResultIndex = () => {
    if (resultIndex === 0) {
      setResultIndex(resultsLength - 1)
    } else {
      setResultIndex(resultIndex - 1)
    }
  }

  const incrementResultIndex = () => {
    if (resultIndex === resultsLength - 1) {
      setResultIndex(0)
    } else {
      setResultIndex(resultIndex + 1)
    }
  }

  const handleWorkNextEnter = () => {
    setNextHovered(true)
    dispatch({ type: 'OPEN_CURSOR', payload: 'View' })
  }

  const handleWorkNextLeave = () => {
    setNextHovered(false)
    dispatch({ type: 'CLOSE_CURSOR' })
  }

  useEffect(() => {
    dispatch({ type: 'START_SCROLL_TO' })
  }, [dispatch])

  return (
    <>
      <Spacer size={[140, 240]} />

      <Container>
        <ScrollSection>
          <Grid>
            <GridItem tabletL={3} tabletLStart={2}>
              <TextBodySmall>
                <AnimateSplitText delay={loadADelay}>{name}</AnimateSplitText>
              </TextBodySmall>
            </GridItem>

            <GridItem tabletL={4} tabletLStart={2}>
              <Heading2>
                <AnimateSplitText delay={loadBDelay}>
                  {title}
                  <Tetris inline />
                </AnimateSplitText>
              </Heading2>
            </GridItem>
          </Grid>
        </ScrollSection>

        {challenge && (
          <ScrollSection>
            <Grid>
              <GridItem tabletL={4} tabletLStart={3}>
                <Spacer size={[50, 165]} />

                <WorkSection>
                  <WorkSectionHeader>
                    <TextUILabel>
                      <AnimateSlideIn delay={loadBDelay}>
                        Challenge
                      </AnimateSlideIn>
                    </TextUILabel>
                  </WorkSectionHeader>

                  <Spacer size={[16, 34]} />

                  <Flow>
                    <RichText content={challenge} delay={loadBDelay} />
                  </Flow>
                </WorkSection>
              </GridItem>
            </Grid>
          </ScrollSection>
        )}

        {video && (
          <>
            <Spacer size={[40, 90]} />

            <ScrollSection>
              <Grid>
                <GridItem>
                  <AnimateSlideIn>
                    <WorkVideo>
                      <VideoEmbed
                        type={video.type}
                        id={video.videoPlayerId}
                        autoplay={video.autoplay}
                        loop={video.loop}
                      />
                    </WorkVideo>
                  </AnimateSlideIn>
                </GridItem>
              </Grid>
            </ScrollSection>
          </>
        )}

        {image && (
          <>
            <Spacer size={[40, 140]} />

            <ScrollSection>
              <Grid>
                <GridItem>
                  <AnimateSlideIn>
                    <WorkImage>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.title}
                      />
                    </WorkImage>
                  </AnimateSlideIn>
                </GridItem>
              </Grid>
            </ScrollSection>
          </>
        )}

        {solution && (
          <ScrollSection>
            <>
              <Spacer size={[50, 160]} />
              <Grid>
                <GridItem tabletL={4} tabletLStart={3}>
                  <WorkSection>
                    <WorkSectionHeader>
                      <TextUILabel>
                        <AnimateSlideIn>Solution</AnimateSlideIn>
                      </TextUILabel>
                    </WorkSectionHeader>

                    <Spacer size={[16, 34]} />

                    <AnimateSlideIn>
                      <Flow scale={2}>
                        <RichText content={solution} />
                      </Flow>
                    </AnimateSlideIn>
                  </WorkSection>
                </GridItem>
              </Grid>
            </>
          </ScrollSection>
        )}

        {results && (
          <>
            <Spacer size={[50, 165]} />

            <ScrollSection>
              <Grid>
                <GridItem tabletL={1} tabletLStart={3}>
                  <WorkSection>
                    <WorkSectionHeader>
                      <TextUILabel>
                        <AnimateSlideIn>Results</AnimateSlideIn>
                      </TextUILabel>
                    </WorkSectionHeader>

                    <Spacer size={10} />
                  </WorkSection>
                </GridItem>

                <GridItem tabletL={2} tabletLStart={3}>
                  <Heading2>
                    <AnimateSlideIn>
                      {resultIndexPad}/{resultsLengthPad}
                    </AnimateSlideIn>
                  </Heading2>
                </GridItem>

                <GridItem tabletL={3} tabletLStart={5}>
                  <WorkResults>
                    <WorkResultsTrack activeIndex={resultIndex}>
                      {React.Children.toArray(
                        results.map(result => (
                          <WorkResult>
                            <TextBodyLarge>
                              <AnimateSplitText>
                                {result.resultText.resultText}
                              </AnimateSplitText>
                            </TextBodyLarge>
                          </WorkResult>
                        ))
                      )}
                    </WorkResultsTrack>
                  </WorkResults>

                  <Spacer size={[60, 160]} />

                  <WorkResultsFooter>
                    <WorkResultsNav>
                      <IconButton
                        onClick={decrementResultIndex}
                        onMouseEnter={() =>
                          dispatch({ type: 'OPEN_CURSOR_LINK' })
                        }
                        onMouseLeave={() =>
                          dispatch({ type: 'CLOSE_CURSOR_LINK' })
                        }
                        label="Previous result"
                        icon={<IconArrowheadLeft />}
                      />
                      <Spacer size={[12, 15]} axis="horizontal" />
                      <IconButton
                        onClick={incrementResultIndex}
                        onMouseEnter={() =>
                          dispatch({ type: 'OPEN_CURSOR_LINK' })
                        }
                        onMouseLeave={() =>
                          dispatch({ type: 'CLOSE_CURSOR_LINK' })
                        }
                        label="Next result"
                        icon={<IconArrowheadRight />}
                      />
                    </WorkResultsNav>

                    <Tetris />
                  </WorkResultsFooter>

                  <Spacer size={[90, 154]} />
                </GridItem>
              </Grid>
            </ScrollSection>
          </>
        )}

        {next && (
          <ScrollSection>
            <AnimateSlideIn>
              <WorkNext
                onMouseEnter={handleWorkNextEnter}
                onMouseLeave={handleWorkNextLeave}
              >
                <PageTransitionLink to={`${routes.work}/${next.slug}`}>
                  <Grid>
                    <GridItem tabletL={5} tabletLStart={3}>
                      <WorkNextHR hovered={nextHovered} />
                      <Spacer size={[24, 46]} />
                    </GridItem>

                    <GridItem tabletL={2} tabletLStart={3}>
                      <TextBodyLarge>Next</TextBodyLarge>
                    </GridItem>

                    <GridItem tabletL={2} tabletLStart={5}>
                      <Heading3 maxWidth={8}>{next.name}</Heading3>
                    </GridItem>
                  </Grid>
                  <Spacer size={[24, 46]} />
                </PageTransitionLink>
              </WorkNext>
            </AnimateSlideIn>
          </ScrollSection>
        )}
      </Container>

      <ScrollSection threshold={0.2}>
        <Footer />
      </ScrollSection>
    </>
  )
}

Work.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  challenge: PropTypes.object,
  solution: PropTypes.object,
  video: PropTypes.object,
  image: PropTypes.object,
  results: PropTypes.array,
  next: PropTypes.object,
}

export default Work
