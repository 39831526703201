import styled from 'styled-components'

export const VideoEmbedMain = styled.div`
  position: relative;

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
`

export const VideoEmbedIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

export const VideoEmbedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
